import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PageBanner from "../components/banners/pageBanner"
import ContactForm from "../components/contactForm"

const Page = props => {
	const {
		data: {
			wpgraphql: { page }
		},
	} = props;

	const { title, content } = page;

	const marginClass = ( page.acfPage.narrowMargins ) ? 'is-narrow constrained' : 'constrained';

	return (
		<Layout showAlternateCta={page.acfPage.showAlternateCta} >
			<article>
				<PageBanner 
					title={title}
					description={page.acfPage.seoDescription}
				/>
				<div id="article-content" className="entry-content page-content">
					<section className="content light">
						<div className={marginClass} dangerouslySetInnerHTML={{ __html: content }}>
						</div>
						<ContactForm 
							page={title}
						/>
					</section>
				</div>
			</article>
		</Layout>
	)
}

export default Page;

export const pageQuery = graphql`
	query GET_PAGE($id: ID!) {
		wpgraphql {
			page(id: $id) {
				title(format: RENDERED)
				content(format: RENDERED)
				uri
				acfPage {
				 	narrowMargins
					showAlternateCta
					seoDescription
				}
			}
		}
	}
`