import React from "react"

const ContactForm = props => {
	if ( props.page !== 'Contact' ) {
		return null;
	}

	return (
		<div className="form__wrap is-narrow constrained">
			<form className="form" method="post" action="#" data-netlify="true" name="contact">
				<input type="hidden" name="form-name" value="contact" />
				<div className="form__field form__field--half is-required">
					<label htmlFor="first-name">First Name</label>
					<input type="text" name="first-name" id="first-name" required />
				</div>
				<div className="form__field form__field--half is-required">
					<label htmlFor="last-name">Last Name</label>
					<input type="text" name="last-name" id="last-name" required />
				</div>
				<div className="form__field form__field--half is-required">
					<label htmlFor="email">Email</label>
					<input type="text" name="email" id="email" required />
				</div>
				<div className="form__field form__field--half">
					<label htmlFor="phone">Phone</label>
					<input type="text" name="phone" id="phone" />
				</div>
				<div className="form__field form__field--half">
					<label htmlFor="company">Company</label>
					<input type="text" name="company" id="company" />
				</div>
				<div className="form__field form__field--half">
					<label htmlFor="url">URL</label>
					<input type="text" name="url" id="url" />
				</div>
				<div className="form__field form__field--full is-required">
					<label htmlFor="message">Message</label>
					<textarea name="message" id="message" rows="8" required />
				</div>
				<div class="form__submit form__field form__field--full">
					<input type="submit" value="Submit" />
				</div>
			</form>
		</div>
	)
}

export default ContactForm
